import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/z-zBnQQ03SY">
    <SEO title="The Introduction - Touching Holiness" />
  </Layout>
)

export default SermonPost
